import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on the use of communications equipment such as laptops and phones.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`This document describes requirements for communication equipment
security configurations of Data Migrators.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all communication equipment that is part of the
data network of Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <ol>
      <li parentName="ol">{`Item`}</li>
      <li parentName="ol">{`Item`}
        <ul parentName="li">
          <li parentName="ul">{`Mixed`}</li>
          <li parentName="ul">{`Mixed  `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Item`}</li>
    </ol>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}
        <ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol>
      </li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <p parentName="li">{`The security features necessary to minimize risks to
communication equipment must be configured in the equipment
before it is placed into service. There are two possible roles
for the staff that manages the communication equipment:
monitoring and administrator. The monitoring role has read only
privileges. The administrator role is able to change
configuration parameters. All issued commands by users will be
recorded, as well as any other security events that may pose a
threat to the equipment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Local users are not allowed on communication equipment. Everyone
must authenticate through the central repository of users using
a protocol that reduces the risk of identity theft.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All information transmitted from the device must be encrypted by
a strong encryption algorithm to minimize the risks of
eavesdropping on the communications and man-in-the-middle
attacks.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The events recorded by the communication equipment must be kept
in storage media that is subject to a regular backup process.
The process of maintaining these backups must ensure that the
information is not amended.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The password of the communication equipment\\‘s administrator
user must not be known by anyone on the staff that manages the
equipment. If, for any reason, it is necessary to make use of
the highest administrative privileges within the device, then
the staff must file a request to the internal security division
for the password attaching the justification for its use and
completing the required forms. The password must then be reset
by the highest administrator to maintain security.`}</p>
      </li>
    </ol>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec Team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec Team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Eavesdropping`}</li>
      <li parentName="ul">{`Man-in-the-middle Attack`}</li>
      <li parentName="ul">{`Strong Encryption`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      